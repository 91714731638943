import { defineStore } from 'pinia';

const separator = ' | ';

interface State {
	baseTitle: string;
	parts: string[];
}

export const useDocumentTitleStore = defineStore('documentTitle', {
	state: (): State => ({
		baseTitle: '',
		parts: [],
	}),
	getters: {
		title: state => {
			if (state.parts.length === 0) {
				return state.baseTitle;
			}
			return state.parts.join(separator) + separator + state.baseTitle;
		},
	},
	actions: {
		prepend(title: string) {
			this.parts = [title, ...this.parts];
		},
		append(title: string) {
			this.parts = [...this.parts, title];
		},
		set(titles: string | string[]) {
			if (typeof titles === 'string') {
				titles = [titles];
			}
			this.parts = titles;
		},
		reset() {
			this.parts = [];
		},
		setBaseTitle(title: string) {
			this.baseTitle = title;
		},
	},
});
