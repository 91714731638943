import { defineStore } from 'pinia';

import { logout, tokenLogin, user } from '@/api/auth';
import { getSettings, saveSettings } from '@/api/settings';
import { UnauthorizedError } from '@/http/errors';
import { Settings, User } from '@/types';

interface State {
	authenticated?: boolean;
	user: User | null;
	settings: Settings;
}

export const useUserStore = defineStore('user', {
	state: (): State => ({
		authenticated: undefined,
		user: null,
		settings: {
			locale: 'la',
		},
	}),
	getters: {
		isAuthenticated: state => !!state.authenticated,
	},
	actions: {
		async tokenLogin(token: string) {
			return tokenLogin(token).then(user => {
				this.user = user;
				this.authenticated = true;
				return user;
			});
		},
		async logout() {
			return logout().then(() => {
				this.authenticated = false;
				this.user = null;
				return;
			});
		},
		async getUser() {
			return user()
				.then(user => {
					this.user = user;
					this.authenticated = true;
					return user;
				})
				.catch((err: Error) => {
					if (err instanceof UnauthorizedError) {
						this.authenticated = false;
						this.user = null;
						return null;
					}
					throw err;
				});
		},
		async getSettings() {
			return getSettings().then(settings => {
				this.settings = settings;
				return settings;
			});
		},
		async saveSettings(settings: Settings) {
			return saveSettings(settings).then(settings => {
				this.settings = settings;
				return settings;
			});
		},
	},
});
