import { Settings } from '@/types';

import client from './client';

export function getSettings(): Promise<Settings> {
	return client.get('/api/settings').then(resp => resp.json());
}

export function saveSettings(settings: Settings): Promise<Settings> {
	return client.put('/api/settings', { ...settings }).then(resp => resp.json());
}
