<template>
	<TransitionRoot as="template" :show="open" @after-leave="afterClose()">
		<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close()">
			<div
				class="flex items-center justify-center min-h-screen pt-6 px-4 pb-20 text-center"
				:class="fullWidth ? '' : ' sm:block sm:p-0'"
			>
				<TransitionChild
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay
						class="fixed inset-0 bg-white/5 opacity-100 transition-opacity"
					/>

					<div class="absolute top-0 right-0 pt-3 pr-3 md:pt-6 md:pr-6">
						<button
							type="button"
							class="text-gray-300 hover:text-gray-500 focus:outline-none focus:ring-0"
							@click="emit('close')"
						>
							<span class="sr-only">Close</span>
							<XMarkIcon class="size-6 md:size-9" aria-hidden="true" />
						</button>
					</div>
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
					&#8203;
				</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					@before-enter="beforeOpen()"
				>
					<div
						class="inline-block align-bottom bg-white dark:bg-martinique-500 rounded-[30px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
						:class="fullWidth ? '!max-w-7xl' : 'w-full lg:w-[--width]'"
						:style="`--width: ${width}px`"
						v-bind="$attrs"
					>
						<div class="flex items-stretch justify-between">
							<div
								v-if="image"
								class="hidden sm:block flex-shrink-0 bg-[#1E1D37]"
							>
								<img class="w-72" :src="image" alt="Login" />
							</div>

							<div class="p-5 md:p-9 text-left flex flex-col flex-grow">
								<div
									class="flex items-center gap-2 md:gap-4 mb-5 md:mb-9"
								>
									<DialogTitle
										as="p"
										class="text-xl md:text-4xl tracking-tight font-bold text-transparent bg-clip-text bg-gradient-to-r from-portage-500 to-lavender-500"
									>
										{{ title }}
									</DialogTitle>

									<slot name="header" />
								</div>

								<slot :close="close" />
							</div>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

import { useOverlayStore } from '@/stores/overlay';

withDefaults(
	defineProps<{
		open: boolean;
		title: string;
		image?: string;
		width?: number;
		fullWidth?: boolean;
	}>(),
	{
		image: undefined,
		width: 700,
		fullWidth: false,
	},
);

const emit = defineEmits<{
	(e: 'close'): void;
	(e: 'closed'): void;
}>();

defineOptions({ inheritAttrs: false });

const overlayStore = useOverlayStore();

function beforeOpen() {
	overlayStore.increment();
}

function afterClose() {
	overlayStore.decrement();
	emit('closed');
}

function close() {
	emit('close');
}
</script>
