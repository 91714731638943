import { User } from '@/types';

import client from './client';

export function user(): Promise<User> {
	return client.get('/api/user').then(resp => resp.json());
}

export function tokenLogin(token: string): Promise<User> {
	return client.post('/api/auth/token', { token }).then(resp => resp.json());
}

export function logout(): Promise<Response> {
	return client.delete('/api/auth/logout');
}
