<template>
	<OverlayBackgroundBlurContainer v-if="ready" class="min-h-full relative flex flex-col">
		<RouterView />

		<AppNotifications />
	</OverlayBackgroundBlurContainer>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import AppNotifications from '@/components/ui/AppNotifications.vue';
import OverlayBackgroundBlurContainer from '@/components/ui/OverlayBackgroundBlurContainer.vue';

import { useDocumentTitleStore } from './stores/documentTitle';
import { useUserStore } from './stores/user';

const router = useRouter();
const { locale } = useI18n({ useScope: 'global' });
const userStore = useUserStore();
const documentTitleStore = useDocumentTitleStore();

const ready = ref(false);

router.isReady().then(() => {
	nextTick(() => (ready.value = true));
});

watch(
	() => userStore.isAuthenticated,
	isAuthenticated => {
		if (!isAuthenticated) {
			return;
		}
		userStore.getSettings();
	},
	{ immediate: true },
);

watch(
	() => userStore.settings,
	settings => {
		locale.value = settings.locale;
	},
	{ immediate: true },
);

documentTitleStore.setBaseTitle(document.title);

watch(
	() => documentTitleStore.title,
	title => (document.title = title),
);
</script>
