import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { useUserStore } from '@/stores/user';

export function ifAuthenticated(_to: RouteLocationNormalized): boolean | RouteLocationRaw {
	const userStore = useUserStore();
	if (!userStore.isAuthenticated) {
		// TODO: Enable this once we have a real login page
		// return { name: 'login', query: { r: to.fullPath } };
		return { name: 'home' };
	}
	return true;
}

export function ifNotAuthenticated(): boolean | RouteLocationRaw {
	const userStore = useUserStore();
	if (userStore.isAuthenticated) {
		return { name: 'search' };
	}
	return true;
}
