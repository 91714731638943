import { defineStore } from 'pinia';

interface State {
	count: number;
}

export const useOverlayStore = defineStore('overlay', {
	state: (): State => ({
		count: 0,
	}),
	actions: {
		increment() {
			this.count++;
		},
		decrement() {
			if (this.count === 0) {
				return;
			}
			this.count--;
		},
	},
});
