<template>
	<AppModal v-slot="{ close }" :open="open" title="Indstillinger" image="/img/code.svg">
		<p class="text-base font-normal tracking-tight leading-relaxed text-[#1E1D37] dark:text-[#E8E8F4]">
			Her kan du ændre dine indstillinger i Lix.
		</p>

		<div class="py-6 flex-grow space-y-10">
			<LocaleSelector v-model="settings.locale" />
		</div>

		<div class="flex items-center justify-between gap-3 flex-wrap">
			<AppSavingIndicator :saving="saving" />

			<button
				type="button"
				class="w-full sm:w-1/2 px-4 py-2 text-sm font-medium text-[#A0AAFE] border border-[#A0AAFE] rounded-full flex items-center justify-center flex-shrink-0 whitespace-nowrap ml-auto"
				@click="close"
			>
				Luk
			</button>
		</div>
	</AppModal>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core';
import { reactive, ref } from 'vue';

import { useUserStore } from '@/stores/user';
import { Settings } from '@/types';

import AppModal from '../AppModal.vue';
import AppSavingIndicator from '../AppSavingIndicator.vue';
import LocaleSelector from '../LocaleSelector.vue';

defineProps<{
	open: boolean;
}>();

const userStore = useUserStore();

const settings = reactive<Settings>({
	locale: userStore.settings.locale,
});

const saving = ref(false);

watchDebounced(
	() => settings,
	settings => {
		saving.value = true;
		userStore.saveSettings(settings).then(() => (saving.value = false));
	},
	{ debounce: 500, deep: true },
);
</script>
