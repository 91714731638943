class ExtendableError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ExtendableError';
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

type ErrorData = {
	error: string;
	message: string;
	validationErrors?: Record<string, string[]>;
};

export class ResponseError extends ExtendableError {
	constructor(
		public response: Response,
		public data: ErrorData,
	) {
		super(data.message);
		this.name = 'ResponseError';
	}

	get status(): number {
		return this.response.status;
	}

	get headers(): Headers {
		return this.response.headers;
	}
}

export class ServerError extends ResponseError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'ServerError';
	}
}

export class ClientError extends ResponseError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'ClientError';
	}
}

export class BadRequestError extends ClientError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'BadRequestError';
	}
}

export class UnauthorizedError extends ClientError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'UnauthorizedError';
	}
}

export class ForbiddenError extends ClientError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'ForbiddenError';
	}
}

export class NotFoundError extends ClientError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'NotFoundError';
	}
}

export class ValidationError extends ClientError {
	constructor(response: Response, data: ErrorData) {
		super(response, data);
		this.name = 'ValidationError';
	}

	get validationErrors(): Record<string, string[]> {
		return this.data?.validationErrors || {};
	}
}

export class RequestError extends ExtendableError {
	constructor(public details: string) {
		super(
			'There was a problem communicating with the server. ' +
				'Please check your internet connection and try again',
		);
		this.name = 'RequestError';
	}
}
