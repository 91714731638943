<template>
	<div class="pt-16 pb-12 flex flex-col bg-white flex-grow flex-shrink-0">
		<main class="container flex-grow flex flex-col justify-center">
			<div class="flex-shrink-0 flex justify-center">
				<a href="/" class="inline-flex">
					<span class="sr-only">Lix</span>
					<img class="h-12 w-auto" src="/img/logo.svg" alt="Lix" />
				</a>
			</div>
			<div class="py-16">
				<div class="text-center">
					<p class="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
						404 fejl
					</p>
					<h1
						class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
					>
						Siden blev ikke fundet.
					</h1>
					<p class="mt-2 text-base text-gray-500">
						Vi kunne desværre ikke finde den side du leder efter.
					</p>
					<div class="mt-6">
						<RouterLink
							:to="{ name: 'home' }"
							class="text-base font-medium text-indigo-600 hover:text-indigo-500"
						>
							Gå til forsiden
							<span aria-hidden="true">&rarr;</span>
						</RouterLink>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>
