/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import router from './router';
import { useUserStore } from './stores/user';

import './assets/css/tailwind.css';

const i18n = createI18n({
	legacy: false,
	locale: 'la',
	messages,
});

const app = createApp(App);
app.use(createPinia());

const userStore = useUserStore();

Promise.resolve()
	.then(() => userStore.getUser())
	.then(() => {
		app.use(router);
		app.use(i18n);
		app.mount('#app');
	});
