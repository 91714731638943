import { ref } from 'vue';

let idCounter = 0;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface Notification {
	heading?: string;
	message: string;
	type: NotificationType;
	timeout?: number;
	id: number;
}

export const notifications = ref<Notification[]>([]);

export function notify({
	heading,
	message,
	type,
	timeout,
}: {
	heading?: string;
	message: string;
	type: NotificationType;
	timeout?: number;
}) {
	notifications.value.push({
		id: idCounter++,
		heading,
		message,
		type,
		timeout,
	});
}

export function dismiss(n: Notification) {
	const i = notifications.value.indexOf(n);
	notifications.value.splice(i, 1);
}
