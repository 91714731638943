<template>
	<div :class="overlayStore.count > 0 ? 'blur-2xl' : ''">
		<slot />
	</div>
</template>

<script setup lang="ts">
import { watch } from 'vue';

import { useOverlayStore } from '@/stores/overlay';

const overlayStore = useOverlayStore();

// Handle nested modals as well.
watch(
	() => overlayStore.count,
	count => {
		const portalRoot = document.getElementById('headlessui-portal-root');
		if (!portalRoot) {
			return;
		}

		for (const child of portalRoot.children) {
			const index = Array.prototype.indexOf.call(portalRoot.children, child);
			if (index < count - 1) {
				child.classList.add('blur-2xl');
			} else {
				child.classList.remove('blur-2xl');
			}
		}
	},
);
</script>
