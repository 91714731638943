<template>
	<Transition
		enter-active-class="transform ease-out duration-300 transition"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition ease-in duration-300"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div v-if="show" class="flex items-center justify-between gap-2">
			<Transition
				enter-active-class="transform ease-out duration-300 transition"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="transition ease-in duration-300"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
				mode="out-in"
			>
				<AppSpinner v-if="saving" class="size-8" />
				<CheckCircleIcon v-else class="size-8 text-green-400" aria-hidden="true" />
			</Transition>
			<span class="text-sm font-medium text-gray-400">{{ message }}</span>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

import AppSpinner from './AppSpinner.vue';

const props = withDefaults(
	defineProps<{
		saving: boolean;
		timeoutMs?: number;
		savingMsg?: string;
		savedMsg?: string;
	}>(),
	{
		timeoutMs: 800,
		savingMsg: 'Gemmer...',
		savedMsg: 'Gemt',
	},
);

const show = ref(false);

let timeout: ReturnType<typeof setTimeout>;

const message = computed(() => (props.saving ? props.savingMsg : props.savedMsg));

watch(
	() => props.saving,
	saving => {
		if (saving) {
			show.value = true;
			return;
		}
		timeout = setTimeout(() => (show.value = false), props.timeoutMs);
	},
	{
		immediate: true,
	},
);

onBeforeUnmount(() => {
	if (timeout) {
		clearTimeout(timeout);
	}
});
</script>
