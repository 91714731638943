import { createRouter, createWebHistory } from 'vue-router';

import { useDocumentTitleStore } from '@/stores/documentTitle';

import routes from './routes';

const router = createRouter({
	history: createWebHistory(),
	routes,
	// scrollBehavior(to, from, savedPosition) {
	// 	if (savedPosition) {
	// 		return savedPosition;
	// 	}

	// 	if (to.hash) {
	// 		return {
	// 			el: to.hash,
	// 			behavior: 'smooth',
	// 		};
	// 	}

	// 	if (from.path === to.path) {
	// 		return {};
	// 	}

	// 	return {
	// 		top: 0,
	// 		left: 0,
	// 	};
	// },
});

// router.beforeEach(async () => {
// 	console.log('router beforeEach');

// 	const promises: Promise<unknown>[] = [];

// 	if (promises.length === 0) {
// 		return true;
// 	}

// 	await Promise.all(promises);

// 	console.log('router beforeEach done');
// });

router.afterEach(to => {
	const documentTitleStore = useDocumentTitleStore();

	if (to.meta.title) {
		documentTitleStore.set(to.meta.title as string);
	}
});

export default router;
