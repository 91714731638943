<template>
	<div>
		<p class="text-sm font-semibold leading-6 text-gray-900 dark:text-[#E8E8F4]">Grammatiske betegnelser</p>
		<p class="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300">
			Vælg om du vil se grammatiske betegnelser på dansk eller latin.
		</p>
		<div class="mt-6 space-y-6">
			<div v-for="l in locales" :key="l.value" class="flex items-center gap-x-3">
				<input
					:id="'locale-' + l.value"
					name="locale"
					type="radio"
					class="size-4 border-gray-300 text-[#0095FF] focus:ring-[#0095FF]"
					:value="l.value"
					:checked="modelValue === l.value"
					@input="emit('update:modelValue', l.value)"
				/>
				<label
					:for="'locale-' + l.value"
					class="block text-sm font-medium leading-6 text-gray-900 dark:text-[#E8E8F4]"
				>
					{{ l.label }}
				</label>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
defineProps<{
	modelValue: string;
}>();

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void;
}>();

const locales = [
	{
		label: 'Latin',
		value: 'la',
	},
	{
		label: 'Dansk',
		value: 'da',
	},
];
</script>
