<template>
	<Switch
		v-model="enabled"
		class="relative inline-flex flex-shrink-0 h-5 w-10 md:h-7 md:w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0"
		:class="[enabled ? 'bg-martinique-400' : 'bg-gray-200']"
		@update:model-value="onToggle"
	>
		<span class="sr-only">Toggle dark mode</span>
		<span
			class="pointer-events-none relative inline-block size-4 md:size-6 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
			:class="[
				enabled ? 'translate-x-5 md:translate-x-7 bg-martinique-500' : 'translate-x-0 bg-white',
			]"
		>
			<span
				class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
				:class="[
					enabled
						? 'opacity-0 ease-out duration-100'
						: 'opacity-100 ease-in duration-200',
				]"
				aria-hidden="true"
			>
				<SunIcon class="size-2 md:size-4 text-yellow-400" />
			</span>
			<span
				class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
				:class="[
					enabled
						? 'opacity-100 ease-in duration-200'
						: 'opacity-0 ease-out duration-100',
				]"
				aria-hidden="true"
			>
				<MoonIcon class="size-2 md:size-4 text-martinique-100" />
			</span>
		</span>
	</Switch>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue';
import { MoonIcon, SunIcon } from '@heroicons/vue/20/solid';
import { ref } from 'vue';

const enabled = ref(
	localStorage.theme === 'dark' ||
		(!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches),
);

function onToggle() {
	if (enabled.value) {
		localStorage.theme = 'dark';
		document.documentElement.classList.add('dark');
	} else {
		localStorage.theme = 'light';
		document.documentElement.classList.remove('dark');
	}
}
</script>
