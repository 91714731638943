<template>
	<div
		class="max-w-sm w-full bg-white dark:bg-martinique-400 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
	>
		<div class="p-4">
			<div class="flex items-start">
				<div class="flex-shrink-0">
					<Component :is="icon" class="size-6" aria-hidden="true" :class="iconClasses" />
				</div>
				<div class="ml-3 w-0 flex-1 pt-0.5">
					<p
						v-if="notification.heading"
						class="text-sm font-medium text-gray-900 dark:text-martinique-50 mb-1"
					>
						{{ notification.heading }}
					</p>
					<p class="text-sm text-gray-500 dark:text-martinique-100">
						{{ notification.message }}
					</p>
				</div>
				<div class="ml-4 flex-shrink-0 flex">
					<button
						type="button"
						class="rounded-md inline-flex text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none focus:ring-0"
						@click="dismiss"
					>
						<span class="sr-only">Close</span>
						<XMarkIcon class="size-5" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { computed, defineComponent, onMounted } from 'vue';

import { Notification } from '@/notifications';

const props = defineProps<{
	notification: Notification;
}>();

const emit = defineEmits<{
	(e: 'dismiss'): void;
}>();

let timeout: ReturnType<typeof setTimeout>;

onMounted(() => {
	if (props.notification.timeout) {
		timeout = setTimeout(dismiss, props.notification.timeout);
	}
});

const icons: { [k: string]: ReturnType<typeof defineComponent> } = {
	'success-icon': CheckCircleIcon,
	'warning-icon': ExclamationCircleIcon,
	'info-icon': InformationCircleIcon,
	'error-icon': XCircleIcon,
};

const icon = computed(() => icons[`${props.notification.type}-icon`]);

const iconClasses = computed(() => {
	switch (props.notification.type) {
		case 'success':
		default:
			return 'text-green-400';
		case 'info':
			return 'text-blue-400';
		case 'warning':
			return 'text-yellow-400';
		case 'error':
			return 'text-red-400';
	}
});

function dismiss() {
	if (timeout) {
		clearTimeout(timeout);
	}

	emit('dismiss');
}
</script>
