import {
	BadRequestError,
	ClientError,
	ForbiddenError,
	NotFoundError,
	RequestError,
	ServerError,
	UnauthorizedError,
	ValidationError,
} from './errors';

// Polyfill for AbortSignal.timeout()
AbortSignal.timeout ??= function timeout(ms) {
	const ctrl = new AbortController();
	setTimeout(() => ctrl.abort(), ms);
	return ctrl.signal;
};

export default class Client {
	get(url: string): Promise<Response> {
		return this.do(url, 'GET');
	}

	post(url: string, data: Record<string, unknown> | FormData): Promise<Response> {
		return this.do(url, 'POST', data);
	}

	put(url: string, data: Record<string, unknown> | FormData): Promise<Response> {
		return this.do(url, 'PUT', data);
	}

	patch(url: string, data: Record<string, unknown> | FormData): Promise<Response> {
		return this.do(url, 'PATCH', data);
	}

	delete(url: string): Promise<Response> {
		return this.do(url, 'DELETE');
	}

	private async do(
		url: string,
		method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
		data?: Record<string, unknown> | FormData,
	): Promise<Response> {
		const headers = new Headers({
			Accept: 'application/json',
		});

		// if (method !== 'GET') {
		// 	if (!Cookies.get('XSRF-TOKEN')) {
		// 		await this.getCsrfToken();
		// 	}
		// 	headers.append('X-XSRF-TOKEN', Cookies.get('XSRF-TOKEN') || '');
		// }

		let body: string | FormData | null = null;
		if (data instanceof FormData) {
			body = data;
		} else if (data) {
			headers.set('Content-Type', 'application/json');
			body = JSON.stringify(data);
		}

		const req = new Request(url, {
			method,
			headers,
			cache: 'default',
			credentials: 'include',
			signal: AbortSignal.timeout(30000), // 30s timeout
			body,
		});

		return fetch(req)
			.catch(err => {
				throw new RequestError(err.message);
			})
			.then(async resp => {
				if (!resp.ok) {
					const json = await resp.json();
					if (resp.status >= 500) {
						throw new ServerError(resp, json);
					} else if (resp.status === 400) {
						throw new BadRequestError(resp, json);
					} else if (resp.status === 401) {
						throw new UnauthorizedError(resp, json);
					} else if (resp.status === 403) {
						throw new ForbiddenError(resp, json);
					} else if (resp.status === 404) {
						throw new NotFoundError(resp, json);
					} else if (resp.status === 422) {
						throw new ValidationError(resp, json);
					} else {
						throw new ClientError(resp, json);
					}
				}
				return resp;
			});
	}

	// private async getCsrfToken(): Promise<string | undefined> {
	// 	await this.get('/sanctum/csrf-cookie');
	// 	const token = Cookies.get('XSRF-TOKEN');
	// 	return token;
	// }
}
